
import { entitiesSearchResult, myEntities, mockRiskIndicators } from "./mockdata"

import myEntityDetail from '../mockdata/my_entity_detail.json'
import sentimentReportList from '../mockdata/sentiment_report_list.json'

export const getSearchEntities = async ({title, industries, scale}) => {
    console.log({title, industries, scale})
    return entitiesSearchResult
}

export const getMyEntities = async () => {

    return myEntities
}

export const getMyEntityDetails = async () => {

    return myEntityDetail
}

export const deleteSelectedEntities = async (entities) => {

    return {
        "status": "Success"
    }
}

export const deleteSelectedEntity = async (entity) => {
    return {
        "status": "Success"
    }
}

export const getRiskIndicators = async () => {
    return mockRiskIndicators;
}


export const createRiskReport = async (data) => {
    return {
        "status": "Success"
    }
}

export const getSentimentReports = async() => {
    return sentimentReportList
}

export const deleteSentimentReport = async (id) => {

}