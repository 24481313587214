const logoutSvg = `
<svg width="85px" height="17px" viewBox="0 0 85 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="back-up-trim" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Companyinfo" transform="translate(-1329.000000, -22.000000)" fill="#FFFFFF">
            <g id="Header_dark" transform="translate(-1.000000, -3.000000)">
                <text id="Logout" font-family="OpenSans-Regular, Open Sans" font-size="14" font-weight="normal" letter-spacing="1">
                    <tspan x="1344" y="38">Logout</tspan>
                </text>
                <text id="|" font-family="OpenSans-Regular, Open Sans" font-size="14" font-weight="normal" letter-spacing="1">
                    <tspan x="1327" y="38">|</tspan>
                </text>
                <text id="ion-log-out---Ionicons" font-family="Ionicons" font-size="15" font-weight="400">
                    <tspan x="1401" y="38"></tspan>
                </text>
            </g>
        </g>
    </g>
</svg>`

export default logoutSvg