import { useState, useEffect } from 'react'
import { Pie, WordCloud } from '@ant-design/plots';
import { Descriptions, Typography, Space, Button, List, Table, Badge, theme, Layout, Form, Radio, Input, Select, Tag, DatePicker, Tabs, Col, Row, Card, Alert, Divider, Statistic, Checkbox, Popconfirm } from 'antd';
import { DownOutlined, UpOutlined, PlusOutlined, DownloadOutlined, ArrowLeftOutlined, LikeOutlined, DislikeOutlined, InfoCircleOutlined } from '@ant-design/icons';
// import RiskRports from '../../components/Icons/RiskReports';
// import sentimentIcon from '../../components/Icons/Sentiments';
import myEntitiesIcon from '../../components/Icons/MyEntities';
import {
  PageContainer,
  ProCard,
  ProConfigProvider,
  ProLayout,
  SettingDrawer,
} from '@ant-design/pro-components';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
import StatusBadge from '../../components/Badges/StatusBadge';
import { deleteSelectedEntities, getMyEntities, getSearchEntities, deleteSelectedEntity } from '../../services/api';

const CheckboxGroup = Checkbox.Group;

const { RangePicker } = DatePicker;
const { Header, Sider, Content } = Layout;
const { Title, Text } = Typography;

const { Option } = Select;

const EngagementBasicInfo = (props) => {
  const navigate = useNavigate();
  const dateFormat = 'YYYY-MM-DD';

  const onClickBack = () => { navigate("/entities") }


  return (
    <>
      <div style={{ boxShadow: "rgba(0, 21, 41, 0.15) 0px 1px 4px 0px" }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: 80, marginLeft: 24, marginRight: 24 }}>
          <Space align='center'>
            <Button size="large" type="text" icon={<ArrowLeftOutlined />} onClick={onClickBack} />
            <div dangerouslySetInnerHTML={{ __html: myEntitiesIcon }}></div>
            <Title level={3}>管理我的公司</Title>
          </Space>
          <Space align="center">
            {/* <Button key="3">编辑我的公司</Button> */}
            {/* <Button key="2" icon={<DownloadOutlined />} >导出报告</Button> */}
          </Space>
        </div>

      </div>
    </>
  )
}

const FilterEntityForm = ({form, handleSearchResult}) => {
    const [ isLoading, setIsLoading ] = useState(false);
   
    const [checkedIndustriesList, setCheckedINdustriesList] = useState([]);
    const [checkedScalesList, setCheckedScalesList] = useState([]);
    const industries = [
        { value: "Technology", label: "科技" },
        { value: "Consumer Discretionary", label: "消费者可选" },
        { value: "Financials", label: "金融" },
        { value: "Health Care", label: "医疗保健" },
        { value: "Communication Services", label: "通信服务" },
        { value: "Materials", label: "材料" },
        { value: "Industrials", label: "工业" },
        { value: "Consumer Staples", label: "消费者必需" },
        { value: "Real Estate", label: "房地产" },
        { value: "Utilities", label: "公用事业" },
        { value: "Energy", label: "能源" }
      ];

      const scales = [
        {value: 'large', label: "大型"},
        {value: 'medium', label: "中型"},
        {value: 'small', label: "小型"},
      ]


      const handleSelectAll = () => {
        console.log("click")
        form.setFieldsValue({
          industries: ["all"]
        });
      };
      const handleDeselectAll = () => {
        form.setFieldsValue({
          industries: []
        });
      };

      const handleChagne = (value) => {
        console.log("value: ", value)
        console.log("value[-1]: ", value[value.length -1])
        if (value[value.length -1] === 'all' ) {
            handleSelectAll()
        } else if (value.length > 0 && value[0] === "all") {
            console.log(value.splice(0, 1))
            form.setFieldsValue({
                industries: value.splice(0, 1),
              });
        }
      }
      const handleRestForm = () => {
        form.resetFields()
        setCheckedINdustriesList([])
        setCheckedScalesList([])
      }
      const options = industries.map((industry) => {
        return <Option key={industry.value} value={industry.value}>{industry.label}</Option>;
      });

      const onClickSubmit = async() => {
        setIsLoading(true);
        const values = form.getFieldsValue();
        console.log("values: ", values)
        const searchResults = await getSearchEntities(values);
        console.log("searchResults");
        console.log(searchResults)
        handleSearchResult(searchResults)
        setIsLoading(false);
      }
    
      console.log("form.getFieldValue()", form.getFieldValue("scale"))

    return <><Form
    form={form}
    labelCol={{ span: 4 }}
    wrapperCol={{ span: 20 }}
    layout={'inline'}
    style={{marginBottom: 24}}
  >
    <Form.Item label="企业名称/证券代码" name="title" style={{flex: 1, minWidth: 150}}>
      <Input placeholder="输入股票代码或股票名称" />
      
    </Form.Item>
  </Form>
  <Form
    form={form}
    labelCol={{ span: 4 }}
    wrapperCol={{ span: 20 }}
    // layout={'inline'}
    style={{marginBottom: 24}}
  >
    <Form.Item label="行業" name="industries" style={{ minWidth: 150}}>
        <Space>
    <Button type="primary" onClick={() => {console.log(form.getFieldsValue()); const all =  industries.map(i => i.value); form.setFieldsValue({industries: all}); setCheckedINdustriesList(all);}}>不限</Button>
    <CheckboxGroup
    options={industries}
    value={checkedIndustriesList}
    onChange={(values) => {form.setFieldValue({"industries": values}); setCheckedINdustriesList(values)}}
    />
    </Space>
    </Form.Item>
    <Form.Item label="规模" name="scale" style={{ minWidth: 150}}>
        <Space>
    <Button type="primary" onClick={() => { const all = scales.map(i => i.value); form.setFieldsValue({scale: all }); setCheckedScalesList(all)}}>不限</Button>
    <CheckboxGroup options={scales}
    value={checkedScalesList}
    onChange={(values) => { console.log("values", values); form.setFieldValue({"scale": values}); setCheckedScalesList(values)}} />
    </Space>
    </Form.Item>
      <Form.Item  wrapperCol={{ offset: 4, span: 20 }}>
      <Space>
      <Button type="primary" onClick={onClickSubmit}>搜索</Button>
      <Button onClick={handleRestForm}>Reset</Button>
      </Space>
      </Form.Item>

  </Form>
  </>
}

const RiskReportDetails = (props) => {
    const [form] = Form.useForm();
    const [ searchEntitiesData, setSearchEntitiesData ] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [selectedMyEntitiesKeys, setSelectedMyEntitiesKeys] = useState([]);
    const [myEntities, setMyEntities ] = useState([]);

    useEffect(() => {
        const fetchPost = async () => {
           const data = await getMyEntities()
           console.log("fetchPost: ", data);
           setMyEntities(data);
        };
        fetchPost();
     }, []);


  const {
    token: { colorBgLayout, colorBgContainer },
  } = theme.useToken();

  const columns = [
    {
      title: '公司名称',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '证券代码',
      dataIndex: 'stock_code',
      key: 'stock_code',
    },
    {
      title: '行业',
      dataIndex: 'industry',
      key: 'industry',
    },
    {
      title: '生命周期',
      dataIndex: 'lifecycle',
      key: 'lifecycle',
    },
    {
      title: '企业性质',
      dataIndex: 'scale',
      key: 'scale',
    },
  ];

  const rowSelection = {
    type: 'checkbox',
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedKeys(selectedRowKeys)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
    selectedRowKeys: selectedKeys,
  };

  const onClickAddSelectedEntities = () => {
    const entities = selectedKeys;
    setSelectedKeys([]);
  }

  const renderRiskOverview = () => {
    
    return <Card title={`添加我的公司`} bordered={false} bodystyle={{ height: 320 }} >
    <FilterEntityForm form={form} handleSearchResult={setSearchEntitiesData} />
    { selectedKeys.length > 0 && <Text strong>选了{selectedKeys.length}个项目</Text>}
    <Table dataSource={searchEntitiesData} columns={columns} rowSelection={rowSelection}/>
    <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 24,}}>
        <Button type="primary" disabled={selectedKeys.length ===  0 } onClick={onClickAddSelectedEntities}>添加已选公司</Button>
    </div>
    </Card>
  }



  const renderRiskIndicatorsListDetails = () => {
    


    const columns = [
        {
            title: '公司名称',
            dataIndex: 'title',
            key: 'title',
          },
          {
            title: '证券代码',
            dataIndex: 'stock_code',
            key: 'stock_code',
          },
          {
            title: '行业',
            dataIndex: 'industry',
            key: 'industry',
          },
          {
            title: '生命周期',
            dataIndex: 'lifecycle',
            key: 'lifecycle',
          },
          {
            title: '企业性质',
            dataIndex: 'scale',
            key: 'scale',
          },
      {
        title: '操作',
        dataIndex: 'actions',
        key: 'actions',
        render: (_, record) => (
          <Space size="middle">
            <a>详情</a>
            <a>风险评估</a>
            <Popconfirm
    title="删除选定的公司 "
    description="您确定要删除此公司吗？"
    onConfirm={async () => {await deleteSelectedEntity(record.id)}}
    // onCancel={cancel}
    okText="Yes"
    cancelText="No"
  ><a size="small" type="text">
删除</a></Popconfirm>
          </Space>
        ),
      },
    ];


    const rowSelection = {
        type: 'checkbox',
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          setSelectedMyEntitiesKeys(selectedRowKeys)
        },
        getCheckboxProps: (record) => ({
          disabled: record.name === 'Disabled User',
          // Column configuration not to be checked
          name: record.name,
        }),
        selectedRowKeys: selectedMyEntitiesKeys,
      };

    return <Card title="我的公司" bordered={false} >
        { selectedMyEntitiesKeys.length > 0 && <Space style={{marginBottom: 24}}><Text strong>选了{selectedMyEntitiesKeys.length}个项目</Text> <Popconfirm
    title="删除选定的公司 "
    description="您确定要删除此公司吗？"
    onConfirm={async () => {await deleteSelectedEntities(selectedMyEntitiesKeys)}}
    // onCancel={cancel}
    okText="Yes"
    cancelText="No"
  ><Button size="small">
删除</Button></Popconfirm></Space>}
      <Table dataSource={myEntities} columns={columns} rowSelection={rowSelection}/>
    </Card>
  }

  return <>
    <Layout style={{ height: "100vh -200px" }}><Content
      style={{
        margin: '24px 16px',
        padding: 24,
        minHeight: 280,
        // background: colorBgContainer,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>{renderRiskOverview()}</Col>
        <Col span={24}>
          {renderRiskIndicatorsListDetails()}
        </Col>
      </Row>
    </Content></Layout>

  </>
}



const EditEntitiesPage = () => {

// console.log(JSON.stringify(data))
  return (
    <>
      <EngagementBasicInfo/>
      <RiskReportDetails/>
    </>
  )
}


export default EditEntitiesPage