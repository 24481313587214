import React from 'react';
import { Button, ConfigProvider } from 'antd';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import 'antd/dist/reset.css';
import './App.css';

import Root from "./routes/root";

import ErrorPage from "./error-page";
import MyEntitiesPage from './pages/Entities/MyEntities';
import EditEntitiesPage from './pages/Entities/EditEntities';
import RiskReportList from './pages/Risks/RiskReportList';
import RiskReportDetail from './pages/Risks/RiskReportDetail';
import SentimentReportListPage from './pages/Risks/SentimentReportList';
import SentimentReportDetail from './pages/Risks/SentimentReportDetail';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root ></Root>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "entities",
        element: <MyEntitiesPage />,
      },
      {
        path: "entities/edit",
        element: <EditEntitiesPage />,
      },
      {
        path: "risks",
        element: <RiskReportList />,
      },
      {
        path: "risks/:reportId",
        element: <RiskReportDetail />,
      },
      {
        path: "industries",
        element: <MyEntitiesPage />,
      },
      {
        path: "sentiment",
        element: <SentimentReportListPage />,
      },
      {
        path: "sentiment/:reportId",
        element: <SentimentReportDetail />,
      },
    ],
  },
]);

const App = () => (
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#00b96b',
      },
    }}
  >
    <RouterProvider router={router} />

  </ConfigProvider>
);

export default App;