import { useState } from 'react'
import { Pie } from '@ant-design/plots';
import { Descriptions, Typography, Space, Button, List, Table, Badge, theme, Layout, Form, Radio, Input, Select, Tag, DatePicker, Tabs, Col, Row, Card, Alert, Divider} from 'antd';
import { DownOutlined, UpOutlined, PlusOutlined, DownloadOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import RiskRports from '../../components/Icons/RiskReports';
import {
  PageContainer,
  ProCard,
  ProConfigProvider,
  ProLayout,
  SettingDrawer,
} from '@ant-design/pro-components';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";

const { RangePicker } = DatePicker;
const { Header, Sider, Content } = Layout;
const { Title, Text } = Typography;

const { Option } = Select;

const EngagementBasicInfo = (props) => {
  const navigate = useNavigate();
  const { start_date = "2022-01-01", end_date = "2022-12-31", create_by = "Leung, Brent Yun Chuen", created_at = "2023-06-01 12:00:00" } = props;
  const dateFormat = 'YYYY-MM-DD';
  const onClickBack = () => { navigate("/risks") }
  const items = [
    {
      key: '1',
      label: `风险明细`,
      children: <RiskReportDetails />,
    },
    {
      key: '2',
      label: `数据明细`,
      children: <RiskReportDetails />,
    },
    {
      key: '3',
      label: `历史报告`,
      children: <RiskReportListContent />,
    },
  ];

  return (
    <>
      <div style={{ boxShadow: "rgba(0, 21, 41, 0.15) 0px 1px 4px 0px" }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: 80, marginLeft: 24, marginRight: 24 }}>
          <Space align='center'>
            <Button size="large" type="text" icon={<ArrowLeftOutlined />} onClick={onClickBack} />
            <div dangerouslySetInnerHTML={{ __html: RiskRports }}></div>
            <Title level={3}>风险明细</Title>
          </Space>

          <Space align="center">
            <Button key="3">编辑我的公司</Button>
            <Button key="2" icon={<DownloadOutlined />} >导出报告</Button>
          </Space>
        </div>
        <Descriptions>
          <Descriptions.Item label="评估期间"><RangePicker
            defaultValue={[dayjs(start_date, dateFormat), dayjs(end_date, dateFormat)]}
            disabled
          /></Descriptions.Item>
          <Descriptions.Item label="创建人">{create_by}</Descriptions.Item>
          <Descriptions.Item label="创建时间">{created_at}</Descriptions.Item>
        </Descriptions>
        <Tabs defaultActiveKey="1" items={items} />;
      </div>
    </>
  )
}


const RiskReportDetails = (props) => {
  const { start_date = "2022-01-01", end_date = "2022-12-31", create_by = "Leung, Brent Yun Chuen", created_at = "2023-06-01 12:00:00" } = props;
  const dateFormat = 'YYYY-MM-DD';
  const {
    token: { colorBgLayout, colorBgContainer },
  } = theme.useToken();
  
  const renderRiskOverview = () => {

    const data = [
      {
        type: '負面',
        value: 27,
      },
      {
          type: '中性',
          value: 0,
        },
      {
        type: '正面',
        value: 25,
      },
  ];

  const config = {
    height: 250,
      appendPadding: 50,
      data,
      angleField: 'value',
      colorField: 'type',
      radius: 1,
      innerRadius: 0.8,
      legend: false,
      color: ['#FFCE3D', '#FFCE3D', '#86BC25'],
      // interactions: [
      //   {
      //     type: 'element-selected',
      //   },
      //   {
      //     type: 'element-active',
      //   },
      // ],
      statistic: false
    };

    return <Card title="風險評估" bordered={false} style={{height: 400}} bodyStyle={{height: 360}}>
    <Row>
    <Col span={12} >
    <></>
    <Text type="secondary">风险总得分</Text>
    <Title level={2}>42</Title>
    <Alert message="高企業風險" type="warning" showIcon />
    <Divider />
    <Space direction='vertical'>
    <Text type="secondary">评估期间</Text>
    <RangePicker
            defaultValue={[dayjs(start_date, dateFormat), dayjs(end_date, dateFormat)]}
            disabled
          />
          </Space>
      </Col>
      <Col span={12}>
      <Pie {...config} />
      </Col>

    </Row>
  </Card>
  }

  const renderRiskIndicatorsCards = () => {
    const data = [
      {
        type: '負面',
        value: 27,
      },
      {
          type: '中性',
          value: 0,
        },
      {
        type: '正面',
        value: 25,
      },
  ];

  const config = {
    height: 250,
    appendPadding: 30,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.8,
    // legend: false,
    color: ['#FFCE3D', '#FFCE3D', '#86BC25'],
    // interactions: [
    //   {
    //     type: 'element-selected',
    //   },
    //   {
    //     type: 'element-active',
    //   },
    // ],
    statistic: false
  };

  return <Card title="風險計算模型" bordered={false} style={{height: 400}} bodyStyle={{height: 360}}>
    <Row>
    <Col span={24} >
    
      <Pie {...config} />
      </Col>

    </Row>
  </Card>

  }

  const renderRiskIndicatorsListDetails = () => {
    const dataSource = [
      {
        key: '1',
        name: 'Mike',
        age: 32,
        address: '10 Downing Street',
      },
      {
        key: '2',
        name: 'John',
        age: 42,
        address: '10 Downing Street',
      },
    ];
    
    const columns = [
      {
        title: '风险指标',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '类型',
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: '指标分数',
        dataIndex: 'address',
        key: 'address',
      },
      {
        title: '指标权重',
        dataIndex: 'weight',
        key: 'weight',
      },
    ];

    return <Card title="風險計算模型" bordered={false} >
    <Table dataSource={dataSource} columns={columns} />;
  </Card>
  }

  return <>
  <Layout style={{ height: "100vh -200px" }}><Content
    style={{
      margin: '24px 16px',
      padding: 24,
      minHeight: 280,
      // background: colorBgContainer,
    }}
  >
     <Row gutter={[16, 16]}>
      <Col span={12}>{renderRiskOverview()}</Col>
      <Col span={12}>
        {renderRiskIndicatorsCards()}
      </Col>
      <Col span={24}>
        {renderRiskIndicatorsListDetails()}
      </Col>
    </Row>

  </Content></Layout>
   
  </>
}


const RiskReportListTable = () => {

  const data = [
    {
      type: '負面',
      value: 27,
    },
    {
      type: '中性',
      value: 0,
    },
    {
      type: '正面',
      value: 25,
    },
  ];

  const config = {
    height: 350,
    width: 350,
    appendPadding: 10,
    // autoFit: false,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.7,
    legend: false,
    label: {
      //   type: 'outer',
      //   offset: '-50%',
      //   content: '{value}',
      //   style: {
      //     textAlign: 'center',
      //     fontSize: 14,
      //   },
    },
    color: ['#FFCE3D', '#FFCE3D', '#86BC25'],
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: false
  };
  const dataSource = [
    {
      key: '1',
      entity_name: '深圳市绿景房地产开发有限公司',
      risk_score: 32,
      industry: '房地产',
    },
    {
      key: '2',
      entity_name: '正兴隆房地产（深圳）有限公司',
      risk_score: 42,
      industry: '房地产',
    },
    {
      key: '3',
      entity_name: '正兴隆房地产（深圳）有限公司',
      risk_score: 42,
      industry: '房地产',
    },
  ];

  const columns = [
    {
      title: '公司名称',
      dataIndex: 'entity_name',
      key: 'entity_name',
      render: (_, record) => <a>{record.entity_name}</a>
    },
    {
      title: '行业',
      dataIndex: 'industry',
      key: 'industry',
    },
    {
      title: '风险分数',
      dataIndex: 'risk_score',
      key: 'risk_score',
    },
    {
      title: '行业对比',
      dataIndex: 'industry',
      key: 'industry',
      render: (_, record) => <Button>去設置</Button>
    },
    {
      title: '企业資訊',
      dataIndex: 'info',
      key: 'info',
      render: (_, record) => { return <div style={{ width: 200 }}><Pie {...config} /></div> },
    },
    {
      title: '關鍵詞',
      dataIndex: 'keywords',
      key: 'keywords',
      render: (_, record) => (
        <Space size="middle" direction="vertical">
          <Tag color="green">增長</Tag>
          <Tag color="green">領跑</Tag>
          <Tag color="red">動蕩</Tag>
        </Space>
      ),
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <a>删除</a>
          <a>重新评估</a>
        </Space>
      ),
    },
  ];

  return <Table dataSource={dataSource} columns={columns} />;
}

const RiskReportListContent = () => {
  const [form] = Form.useForm();
  const {
    token: { colorBgLayout, colorBgContainer },
  } = theme.useToken();

  const [activeEntity, setActiveEntity] = useState(null);

  return <Layout style={{ height: "100vh -200px" }}><Content
    style={{
      margin: '24px 16px',
      padding: 24,
      minHeight: 280,
      background: colorBgContainer,
    }}
  >
    <Title level={4}>历史报告</Title>
    <RiskReportListTable />

  </Content></Layout>
}

const RiskReportDetail = () => {
  return (
    <>
      <EngagementBasicInfo />
    </>
  )
}


export default RiskReportDetail