import { useState, useEffect } from 'react'
import { Descriptions, Typography, Space, Button, List, Table, Badge} from 'antd';
import { DownOutlined, UpOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import MyEntities from '../../components/Icons/MyEntities';
import {
    PageContainer,
    ProCard,
    ProConfigProvider,
    ProLayout,
    SettingDrawer,
  } from '@ant-design/pro-components';
  import { deleteSelectedEntities, getMyEntities, getSearchEntities, deleteSelectedEntity, getMyEntityDetails } from '../../services/api';
const { Title } = Typography;


const EngagementBasicInfo  = () => {
  

    const [isExpanded, setExpanded ] = useState(false)

    const renderEntityInfo = () => {return (
        <Descriptions title="User Info">
    <Descriptions.Item label="UserName">Zhou Maomao</Descriptions.Item>
    <Descriptions.Item label="Telephone">1810000000</Descriptions.Item>
    <Descriptions.Item label="Live">Hangzhou, Zhejiang</Descriptions.Item>
    <Descriptions.Item label="Remark">empty</Descriptions.Item>
    <Descriptions.Item label="Address">
      No. 18, Wantang Road, Xihu District, Hangzhou, Zhejiang, China
    </Descriptions.Item>
  </Descriptions>
    )
    }
    return (
        <>
        <div style={{ boxShadow: "rgba(0, 21, 41, 0.15) 0px 1px 4px 0px"}}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: 80, marginLeft: 24, marginRight: 24 }}>
        <Space align="center">
            <div dangerouslySetInnerHTML={{__html: MyEntities}}></div>
        <Title level={3}>我的公司</Title>
        {/* <Button shape="circle" icon={isExpanded? <DownOutlined />: <UpOutlined />}  onClick={() => setExpanded(!isExpanded)}/> */}
      </Space>

      {/* <Space align="center">
      <Button key="3">操作</Button>
                <Button key="2">操作</Button>
                <Button
                  key="1"
                  type="primary"
                  onClick={() => {
                    // setNum(num > 0 ? 0 : 40);
                  }}
                >
                  主操作
                </Button>
      </Space> */}



        </div>
        

      {isExpanded? renderEntityInfo() : null}

      </div>
      </>
    )
}

const cos = [
    {
        id: 0,
        title: "深圳市绿景房地产开发有限公司",
    },
    {
        id: 1,
        title: "深圳市绿景房地产开发有限公司",
    },
    {
        id: 2,
        title: "深圳市绿景房地产开发有限公司",
    }
]

const MyEntitiesSideBar = ({companyList, activeEntity, setActiveEntity}) => {

  const navigate = useNavigate();
    const onClickAddButton = () => {}
    const onClickManageButton = () => { navigate("/entities/edit")}
    const onClickEntity = () => {}

    console.log("companyList: ", companyList)

    return (
        <>
        <div style={{ width: 250, borderRight: 1, borderRightStyle: 'solid', borderRightColor: '#ddd'}}>
            <div style={{height: 64, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Space>
            <Button
                  key="1"
                  type="primary"
                  onClick={onClickManageButton}
                >
                  管理公司
                </Button>
                {/* <Button shape="circle" icon={<PlusOutlined />}  onClick={onClickAddButton}/>*/}
                </Space> 
            </div>
            <List
            // pagination={{ position: "bottom", align: "center" }}
    // itemLayout="horizontal"
    dataSource={companyList}
    // bordered

    renderItem={(item, index) => (
      <List.Item
        style={{background: activeEntity == item.id ? 'rgba(134, 188, 37, 0.24)': null}}
        onClick={() => setActiveEntity(item.id)}>
        <List.Item.Meta
        style={{ paddingLeft: 24}}
          title={<a onClick={onClickEntity}>{item.title}</a>}
          description={item.stock_code}
          />
      </List.Item>
    )}
  />
  </div>
        </>
    )

}


const MyEntitiesMainPage = ({activeEntity}) => {
  const [entityDetail, setEntityDetail ] = useState([]); 

  useEffect(() => {
    const fetchPost = async () => {
       const data = await getMyEntityDetails()
       console.log("fetchPost: ", data);
       setEntityDetail(data.item);
    };
    fetchPost();
 }, [activeEntity]);


  const dataSource = [
    {
      key: '1',
      name: 'Mike',
      age: 32,
      address: '10 Downing Street',
    },
    {
      key: '2',
      name: 'John',
      age: 42,
      address: '10 Downing Street',
    },
  ];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
  ];

  return <div style={{ flex: 1, padding: 24}}>
      <Title level={4}>Basic Info</Title>
      <Descriptions title="User Info" column={1} bordered>
    <Descriptions.Item label="Product">Cloud Database</Descriptions.Item>
    <Descriptions.Item label="Billing Mode">Prepaid</Descriptions.Item>
    <Descriptions.Item label="Automatic Renewal">YES</Descriptions.Item>
    <Descriptions.Item label="Order time">2018-04-24 18:00:00</Descriptions.Item>
    <Descriptions.Item label="Usage Time" span={2}>
      2019-04-24 18:00:00
    </Descriptions.Item>
    <Descriptions.Item label="Status" span={3}>
      <Badge status="processing" text="Running" />
    </Descriptions.Item>
    <Descriptions.Item label="Negotiated Amount">$80.00</Descriptions.Item>
    <Descriptions.Item label="Discount">$20.00</Descriptions.Item>
    <Descriptions.Item label="Official Receipts">$60.00</Descriptions.Item>
    </Descriptions>
    {
      entityDetail && entityDetail.financial_metrics && <>
      <Descriptions title="财务指标" column={1} bordered>
        {
          entityDetail.financial_metrics && entityDetail.financial_metrics.map(m => (
            <Descriptions.Item key={m.key} label={m.display_name}>{m.value}</Descriptions.Item>
          ))

        }
  
    </Descriptions></>
    }
      
    {/* <Table dataSource={dataSource} columns={columns} /> */}
    </div>;
}


const MyEntitiesListsSideandMain = () => {

  const [ activeEntity, setActiveEntity ] = useState(null);
  const [myEntities, setMyEntities ] = useState([]); 

  useEffect(() => {
    const fetchPost = async () => {
       const data = await getMyEntities()
       console.log("fetchPost: ", data);
       setMyEntities(data);
    };
    fetchPost();
 }, []);

    return <div style={{ display: 'flex', }}>
    <MyEntitiesSideBar  companyList={myEntities} activeEntity={activeEntity} setActiveEntity={setActiveEntity}/>
    <MyEntitiesMainPage activeEntity={activeEntity}></MyEntitiesMainPage>
    </div>
}

const MyEntitiesPage = () => {
    return (
        <>
        <EngagementBasicInfo />
        <MyEntitiesListsSideandMain/>
        </>
    )
}


export default MyEntitiesPage