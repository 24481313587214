const riskReportIcon = `
<svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="back-up-trim" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Companyinfo" transform="translate(-33.000000, -151.000000)">
            <g id="header" transform="translate(0.000000, 128.000000)">
                <g id="order-title" transform="translate(33.000000, 23.000000)">
                    <g id="logo" fill="#86BC25">
                        <rect id="Rectangle-2" x="0" y="0" width="28" height="28" rx="4"></rect>
                    </g>
                    <text id="pie-chart---anticon" font-family="anticon" font-size="17" font-weight="400" fill="#FFFFFF">
                        <tspan x="6" y="20"></tspan>
                    </text>
                </g>
            </g>
        </g>
    </g>
</svg>`

export default riskReportIcon;