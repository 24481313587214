import { Outlet } from "react-router-dom";
import DefaultHeader from "../components/Header/Header";

export default function Root() {
    return (
      <>
      <DefaultHeader/>
      <Outlet />
      </>
    );
  }