import { Button, Modal, Steps, Form, Select, DatePicker, Row, Col, Collapse, Space, Table, Descriptions, message, Popconfirm} from 'antd';
import dayjs from 'dayjs'
import { useState, useEffect } from 'react';
import { getMyEntities, getRiskIndicators, createRiskReport} from '../../services/api';
import { CloseOutlined } from '@ant-design/icons';
const { Panel } = Collapse;

const CreateRiskReportModal = (props) => {
    const [step, setStep ] = useState(0);
    const [messageApi, contextHolder] = message.useMessage();
    const { open, setOpen } = props;
    const [form] = Form.useForm();

    const [myEntities, setMyEntities ] = useState([]); 
    const [riskIndicators, setRiskIndicators ] = useState([]); 

    useEffect(() => {
      const fetchPost = async () => {
         const data = await getMyEntities()
         console.log("fetchPost: ", data);
         setMyEntities(data);
      };
      const fetchRiskIndicators = async () => {
         const data = await getRiskIndicators()
         console.log("fetchgetRiskIndicators: ", data);
         setRiskIndicators(data);
      };
      fetchPost();
      fetchRiskIndicators();
   }, []);


    const columns = [
      {
        title: '指标（一级）',
        dataIndex: 'group',
        key: 'group',
      },
      {
        title: '指标（二级）',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: '指标权重',
        dataIndex: 'default_weight',
        key: 'default_weight',
        render: (_, {default_weight}) => {
          return `${default_weight}%`
        }
      },
    ];

    



    const renderSteps = (current=0) => {
        return <Steps
        style={{marginBottom: 24}}
        current={current}
        items={[
          {
            title: '報告基本信息',
          },
          {
            title: '风险指标',
          },
          {
            title: '预览',
          },
        ]}
      />
    }

    const renderStep1 = () => {

      const entitiesOptions = myEntities.map(e => ({"value": e.id, label: e.title}))
        
        return <div style={{minHeight: 400}}>
        {renderSteps()}
        <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
        >
        <Form.Item
        label="评估公司"
        name="entity"
        rules={[{ required: true, message: '请选择公司' }]}
        >
        <Select
      options={entitiesOptions}
    />
        </Form.Item>
        <Form.Item
        label="评估开始日期"
        name="start_date"
        rules={[{ required: true, message: '请选择评估开始日期' }]}
        >
        <DatePicker  style={{width: "100%"}} placeholder='开始日期'/>
        </Form.Item>
        <Form.Item
        label="评估結束日期"
        name="end_date"
        rules={[{ required: true, message: '请选择评估結束日期' }]}
        >
        <DatePicker  style={{width: "100%"}} placeholder='結束日期'/>
        </Form.Item>
        </Form>
        </div>
    }

    const renderStep2 = () => {
        
        return<>
            {renderSteps(1)}
            <Space direction="vertical" style={{background: 'white', width: "100%"}}>
            <Collapse>
      <Panel header="添加指标" key="1"collapsible="disabled" style={{width: '100%'}}>
      </Panel>
    </Collapse>
    <Collapse defaultActiveKey={['2']}>
      <Panel header={`已选指标库${riskIndicators.length} 個`} key="2">
      <Table dataSource={riskIndicators} columns={columns} />
      </Panel>
    </Collapse>
    </Space>
        </>
    }
    
    const renderStep3 = () => {
      return <>
        {renderSteps(2)}
        <Descriptions column={1} contentStyle={{ background: 'white'}}>
          <Descriptions.Item label="评估公司">{myEntities.find(e => e.id === form.getFieldValue("entity"))["title"]}</Descriptions.Item>
          <Descriptions.Item label="评估开始日期">{dayjs(form.getFieldValue("start_date")).format('YYYY-MM-DD')}</Descriptions.Item>
          <Descriptions.Item label="评估結束日期">{dayjs(form.getFieldValue("end_date")).format('YYYY-MM-DD')}</Descriptions.Item>
          <Descriptions.Item label="風險指標"><Table style={{ width: '100%'}}dataSource={riskIndicators} columns={columns} /></Descriptions.Item>
        </Descriptions>
      
      </>
    }

  
    const confirmCancel = () => {
      form.resetFields() ; setOpen(false); setStep(0)
    }
    const renderCancelButton  = () => <Popconfirm
    title="取消"
    description="你确定要取消吗？"
    onConfirm={confirmCancel}
    // onCancel={(cancel)} 
    okText="确定"
    cancelText="返回"
  >
  <Button key="back">
  取消
            </Button>
  </Popconfirm>
  const onClickCreateReport = async () => {
   
    const data = form.getFieldsValue()
    const res = await createRiskReport(data);
    
    confirmCancel()
    messageApi.success('成功创建风险报告请求');
  }
    const renderModalButtons = () => {
        if (step === 0) {
            return {
              footer: [
                renderCancelButton(),
                <Button key="submit" type="primary" onClick={async () => { 
                  try {
                      const res = await form.validateFields();
                  console.log("res: ", res)
                  setStep(1)
                      
                  } catch (error) {
                      
                  }

              }}>
                  下一步
                </Button>
              ],
                onOk: async () => { 
                    try {
                        const res = await form.validateFields();
                    console.log("res: ", res)
                    setStep(1)
                        
                    } catch (error) {
                        
                    }

                },
                onCancel: () => { form.resetFields() ; setOpen(false)},
                // okButtonProps: {
                //     disabled: true,
                //   }
            }
        } else if (step === 1) {
          return {
            onOk: async () => { 
                try {
                setStep(2)
                    
                } catch (error) {
                    
                }

            },
            onCancel: confirmCancel,
            // okButtonProps: {
            //     disabled: true,
            //   }
            footer: [
              renderCancelButton(),
              <Button key="back" onClick={() => setStep(0)}>
                上一步
              </Button>,
              <Button key="submit" type="primary" onClick={()=> setStep(2)}>
                预览
              </Button>
            ]
        }
        } else if (step ===2 ){
          return {
            footer: [
              renderCancelButton()
              ,
              <Button key="back" onClick={() => setStep(1)}>
                上一步
              </Button>,
              <Button key="submit" type="primary" onClick={onClickCreateReport}>
                创建报告
              </Button>
            ],
            okText: '创建报告',
            onOk: onClickCreateReport,
            onCancel: () => { form.resetFields() ; setOpen(false)},
            // okButtonProps: {
            //     disabled: true,
            //   }
        }
        }
    }

    const confirm = (e) => {
      console.log(e);
      message.success('Click on Yes');
    };
    const cancel = (e) => {
      console.log(e);
      message.error('Click on No');
    };

    return <>
    {contextHolder}
    <Modal
    title="创建新报告"
    cancelText="取消"
    centered
    open={open}
    onOk={() => setOpen(false)}
    onCancel={() => setOpen(false)}
    width={1000}
    closable={false}
    closeIcon={<Popconfirm
      title="Delete the task"
      description="Are you sure to delete this task?"
      onConfirm={confirm}
      onCancel={cancel}
      okText="Yes"
      cancelText="No"
      
    >
    <CloseOutlined />
    </Popconfirm>}
    maskClosable={false}
    {...renderModalButtons()}
  >
    <Row align="center" style={{paddingTop: 24, paddingBottom: 24}}>
        <Col xs={24} sm={24} md={24} lg={18}>

        {step === 0 && renderStep1()}
        {step === 1 && renderStep2()}
        {step === 2 && renderStep3()}
        </Col>
    </Row>
    

    </Modal>
    </>
}

export default CreateRiskReportModal