import { useState } from 'react'
import { Pie } from '@ant-design/plots';
import { Descriptions, Typography, Space, Button, List, Table, Badge, theme, Layout, Form, Radio, Input, Select, Tag} from 'antd';
import { DownOutlined, UpOutlined, PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import RiskRports from '../../components/Icons/RiskReports';
import {
    PageContainer,
    ProCard,
    ProConfigProvider,
    ProLayout,
    SettingDrawer,
} from '@ant-design/pro-components';
import { useNavigate } from "react-router-dom";
import CreateRiskReportModal from '../../components/Modals/CreateRiskReportModal';
const { Header, Sider, Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const EngagementBasicInfo = (props) => {
  const navigate = useNavigate();
  const {onClickNewReport} = props;
    return (
        <>
            <div style={{ boxShadow: "rgba(0, 21, 41, 0.15) 0px 1px 4px 0px" }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: 80, marginLeft: 24, marginRight: 24 }}>
                <Space align='center'>
                        <div dangerouslySetInnerHTML={{ __html: RiskRports }}></div>
                        <Title level={3}>风险仪表盘</Title>
                    </Space>

                    <Space align="center">
                        <Button type="primary" icon={<PlusOutlined />} onClick={onClickNewReport}> 创建新报告</Button>
                        <Button onClick={() => navigate("/entities/edit") }>编辑我的公司</Button>
                        {/* <Button key="2" icon={<DownloadOutlined />} >导出报告</Button> */}
                    </Space>
                </div>
            </div>
        </>
    )
}


const FilterForm = ({form}) => {
    const industries = [
        { value: "Technology", label: "科技" },
        { value: "Consumer Discretionary", label: "消费者可选" },
        { value: "Financials", label: "金融" },
        { value: "Health Care", label: "医疗保健" },
        { value: "Communication Services", label: "通信服务" },
        { value: "Materials", label: "材料" },
        { value: "Industrials", label: "工业" },
        { value: "Consumer Staples", label: "消费者必需" },
        { value: "Real Estate", label: "房地产" },
        { value: "Utilities", label: "公用事业" },
        { value: "Energy", label: "能源" }
      ];

      const handleSelectAll = () => {
        console.log("click")
        form.setFieldsValue({
          industries: ["all"]
        });
      };
      const handleDeselectAll = () => {
        form.setFieldsValue({
          industries: []
        });
      };

      const handleChagne = (value) => {
        console.log("value: ", value)
        console.log("value[-1]: ", value[value.length -1])
        if (value[value.length -1] === 'all' ) {
            handleSelectAll()
        } else if (value.length > 0 && value[0] === "all") {
            console.log(value.splice(0, 1))
            form.setFieldsValue({
                industries: value.splice(0, 1),
              });
        }
      }
      const handleRestForm = () => {
        form.resetFields()
      }
      const options = industries.map((industry) => {
        return <Option key={industry.value} value={industry.value}>{industry.label}</Option>;
      });
    return <Form
    form={form}
    layout={'inline'}
    style={{marginBottom: 24}}
  >
    <Form.Item label="公司名称" name="title" style={{flex: 1, minWidth: 150}}>
      <Input placeholder="输入股票代码或股票名称" />
    </Form.Item>
    <Form.Item label="行业" name="industries">
      {/* <Input placeholder="input placeholder" /> */}
      <Select
          mode="multiple"
          placeholder="Please select"
          defaultValue={['all']}
          style={{ width: '100%' }}
          onChange={handleChagne}
        //   options={industries}
        >
            <Option key="all" onClick={handleSelectAll}>
            选择全部
          </Option>
          {options}
            </Select>
    </Form.Item>
    <Form.Item>
      <Button type="primary">Submit</Button>
    </Form.Item>
    <Form.Item>
      <Button onClick={handleRestForm}>Reset</Button>
    </Form.Item>
  </Form>
}


const RiskReportListTable  = () => {

    const data = [
        {
          type: '負面',
          value: 27,
        },
        {
            type: '中性',
            value: 0,
          },
        {
          type: '正面',
          value: 25,
        },
    ];

    const config = {
        height: 350,
        width: 350,
        appendPadding: 10,
        // autoFit: false,
        data,
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        innerRadius: 0.7,
        legend: false,
        label: {
        //   type: 'outer',
        //   offset: '-50%',
        //   content: '{value}',
        //   style: {
        //     textAlign: 'center',
        //     fontSize: 14,
        //   },
        },
        color: ['#FFCE3D', '#FFCE3D', '#86BC25'],
        interactions: [
          {
            type: 'element-selected',
          },
          {
            type: 'element-active',
          },
        ],
        statistic: false
      };
    const dataSource = [
        {
          key: '1',
          entity_name: '深圳市绿景房地产开发有限公司',
          risk_score: 32,
          industry: '房地产',
        },
        {
          key: '2',
          entity_name: '正兴隆房地产（深圳）有限公司',
          risk_score: 42,
          industry: '房地产',
        },
        {
          key: '3',
          entity_name: '正兴隆房地产（深圳）有限公司',
          risk_score: 42,
          industry: '房地产',
        },
      ];
      
      const columns = [
        {
          title: '公司名称',
          dataIndex: 'entity_name',
          key: 'entity_name',
          render: (_, record) => <a>{record.entity_name}</a>
        },
        {
          title: '行业',
          dataIndex: 'industry',
          key: 'industry',
        },
        {
          title: '风险分数',
          dataIndex: 'risk_score',
          key: 'risk_score',
        },
        {
          title: '行业对比',
          dataIndex: 'industry',
          key: 'industry',
          render: (_, record) => <Button>去設置</Button>
        },
        {
          title: '企业資訊',
          dataIndex: 'info',
          key: 'info',
          render: (_, record) => { return <div style={{width: 200}}><Pie {...config} /></div> },
        },
        {
          title: '關鍵詞',
          dataIndex: 'keywords',
          key: 'keywords',
          render: (_, record) => (
            <Space size="middle" direction="vertical">
              <Tag color="green">增長</Tag>
              <Tag color="green">領跑</Tag>
              <Tag color="red">動蕩</Tag>
            </Space>
          ),
        },
        {
          title: '操作',
          dataIndex: 'actions',
          key: 'actions',
          render: (_, record) => (
            <Space size="middle">
              <a>删除</a>
              <a>重新评估</a>
            </Space>
          ),
        },
      ];
      
      return <Table dataSource={dataSource} columns={columns} />;
}

const RiskReportListContent = () => {
    const [form] = Form.useForm();
    const {
        token: { colorBgLayout, colorBgContainer},
      } = theme.useToken();

    const [activeEntity, setActiveEntity] = useState(null);

    return <Layout style={{height: "100vh -200px"}}><Content
    style={{
      margin: '24px 16px',
      padding: 24,
      minHeight: 280,
      background: colorBgContainer,
    }}
  >
    <Title level={4}>筛选</Title>
    <FilterForm form={form} />
    <Title level={4}>風險報告</Title>
    <RiskReportListTable />

  </Content></Layout>
}

const MyEntitiesPage = () => {
  const [open, setOpen] = useState(false);

    return (
        <>
            <EngagementBasicInfo onClickNewReport={() => setOpen(true)}/>
            <RiskReportListContent />
            <CreateRiskReportModal open={open} setOpen={setOpen}/>
        </>
    )
}


export default MyEntitiesPage