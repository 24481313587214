import React, {useState} from 'react';
import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Breadcrumb, Layout, Menu, theme, Tabs} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom'
import logoutSvg from './logout';
import logoSvg from './logo';
import MyEntitiesHeader from '../../pages/Entities/MyEntities';
const { Header, Content, Footer, Sider } = Layout;



const DefaultHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
    const [current, setCurrent] = useState('1');

    console.log(location);
    console.log(location.pathname.split("/")[1]);
    
    const items  = [
        {
          key: 'entities',
          label: `我的公司`
        },
        {
          key: 'risks',
          label: `风险仪表盘`
        },
        {
          key: 'industries',
          label: `行业对比`
        },
        {
          key: 'sentiment',
          label: `輿情分析`
        },
      ];

      const onChange = (e) => {
        navigate(`/${e.key}`);
        console.log(e.key);
      };
    return (
        <>
        <Header style={{ display: 'flex', alignItems: 'center', background: 'black', justifyContent: 'space-between'}}>

        <div style={{marginTop: 24}}dangerouslySetInnerHTML={{__html: logoSvg}}></div>
        <div style={{ display: 'flex', alignItems: 'center', }}>
            <span style={{ color: 'white'}}>Leung, Brent Yun Chuen</span>
        <div style={{marginTop: 8, marginLeft: 8}}dangerouslySetInnerHTML={{__html: logoutSvg}}></div>
            </div>
        </Header>
        <Header style={{ display: 'flex', alignItems: 'center', background: 'white', boxShadow: '0 1px 4px 0 rgba(0,21,41,0.15)',}}>
        <Menu onClick={onChange} selectedKeys={[location.pathname.split("/")[1]]} mode="horizontal" items={items} style={{minWidth: 600}} />
        </Header>
        </>
    )
}


export default DefaultHeader