import { useState } from 'react'
import { Descriptions, Typography, Space, Button, List, Table, Badge, theme, Layout, Form, Radio, Input, Select, Tag, DatePicker, Tabs, Col, Row, Card, Alert, Divider, Statistic } from 'antd';
import dayjs from 'dayjs';


const StatusBadge = ({status}) => {
    switch(status.toLowerCase()) {
        case "success":
          return <Badge status="success" text="成功" />
        case "error":
          return <Badge status="error" text="错误" />
        case "processing":
          return <Badge status="processing" text="进行中" />
          default:
            return <Badge status="default" text="没有数据" />
      }
}
export default StatusBadge