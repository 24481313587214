import { useState } from 'react'
import { Pie, WordCloud } from '@ant-design/plots';
import { Descriptions, Typography, Space, Button, List, Table, Badge, theme, Layout, Form, Radio, Input, Select, Tag, DatePicker, Tabs, Col, Row, Card, Alert, Divider, Statistic } from 'antd';
import { DownOutlined, UpOutlined, PlusOutlined, DownloadOutlined, ArrowLeftOutlined, LikeOutlined, DislikeOutlined, InfoCircleOutlined } from '@ant-design/icons';
// import RiskRports from '../../components/Icons/RiskReports';
import sentimentIcon from '../../components/Icons/Sentiments';
import {
  PageContainer,
  ProCard,
  ProConfigProvider,
  ProLayout,
  SettingDrawer,
} from '@ant-design/pro-components';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
import StatusBadge from '../../components/Badges/StatusBadge';

const { RangePicker } = DatePicker;
const { Header, Sider, Content } = Layout;
const { Title, Text } = Typography;

const { Option } = Select;

const EngagementBasicInfo = (props) => {
  const navigate = useNavigate();
  const dateFormat = 'YYYY-MM-DD';
  const { data } = props;
  const { start_date = "2022-01-01", end_date = "2022-12-31", created_by, created_at, status, entity_name} = data;
  const onClickBack = () => { navigate("/sentiment") }


  return (
    <>
      <div style={{ boxShadow: "rgba(0, 21, 41, 0.15) 0px 1px 4px 0px" }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: 80, marginLeft: 24, marginRight: 24 }}>
          <Space align='center'>
            <Button size="large" type="text" icon={<ArrowLeftOutlined />} onClick={onClickBack} />
            <div dangerouslySetInnerHTML={{ __html: sentimentIcon }}></div>
            <Title level={3}>輿情分析: {entity_name}</Title>
          </Space>

          <Space align="center">
            <Button key="3" onClick={() => navigate("/entities/edit") }>编辑我的公司</Button>
            <Button key="2" icon={<DownloadOutlined />} >导出报告</Button>
          </Space>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: 80, marginLeft: 72, marginRight: 24 }}>
        <Descriptions>
          
          <Descriptions.Item label="创建人">{created_by}</Descriptions.Item>
          <Descriptions.Item label="创建时间">{created_at}</Descriptions.Item>
          <Descriptions.Item label="狀態"><StatusBadge status={status}/></Descriptions.Item>
          <Descriptions.Item label="评估期间"><RangePicker
            defaultValue={[dayjs(start_date, dateFormat), dayjs(end_date, dateFormat)]}
            disabled
          /></Descriptions.Item>
        </Descriptions>
        </div>
      </div>
    </>
  )
}


const RiskReportDetails = (props) => {
  const { data } = props;
  const { start_date, end_date, positive_count, negative_count, neutral_count, word_count, entity_name} = data;
  const { create_by = "Leung, Brent Yun Chuen", created_at = "2023-06-01 12:00:00" } = props;
  const dateFormat = 'YYYY-MM-DD';
  const {
    token: { colorBgLayout, colorBgContainer },
  } = theme.useToken();

  const renderRiskOverview = () => {
    const totalCount = negative_count + neutral_count + positive_count;
    const data = [
      {
        type: '負面',
        value: negative_count,
      },
      {
        type: '中性',
        value: neutral_count,
      },
      {
        type: '正面',
        value: positive_count,
      },
    ];

    const config = {
      height: 400,
      appendPadding: 50,
      data,
      angleField: 'value',
      colorField: 'type',
      radius: 1,
      innerRadius: 0.8,
      legend: false,
      color: ['#FFCE3D', '#FFCE3D', '#86BC25'],
      statistic: false
    };
    const gridStyle = {
      width: '33%',
    };

    const wordCloudConfig = {
      height: 300,
      data: word_count,
      wordField: 'display_name',
      weightField: 'count',
      // color: '#122c6a',
      wordStyle: {
        fontFamily: 'Verdana',
        fontSize: [24, 80],
      },
      // 设置交互类型
      interactions: [
        {
          type: 'element-active',
        },
      ],
      state: {
        active: {
          // 这里可以设置 active 时的样式
          style: {
            lineWidth: 3,
          },
        },
      },
    };

    return <Card title={`輿情分析`} bordered={false} bodystyle={{ height: 320 }} >
      <Card.Grid style={gridStyle}><Row gutter={0} style={{ height: '100%'}}>
        <Col span={24}>
          <Card bordered={false} style={{ background: '#eee', height: '100%', padding: 24 }}>
            <Statistic
              title="舆情记录"
              value={totalCount}
              precision={0}
              suffix="条记录"
            />
          </Card>

        </Col>
        <Col span={8}>
          <Card bordered={false} style={{ background: '#FFF1F0' , height: '100%',padding: 24 }}>
            <Statistic
              title="負面舆情"
              prefix={<DislikeOutlined />}
              value={negative_count}
              precision={0}
              valueStyle={{ color: '#FF4D4F' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false} style={{ background: '#E6F7FF', height: '100%',padding: 24  }}>
            <Statistic
              title="中性舆情"
              value={neutral_count}
              prefix={<InfoCircleOutlined />}
              precision={0}
              valueStyle={{ color: '#1890FF' }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false} style={{ background: '#B7EB8F', height: '100%',padding: 24  }}>
            <Statistic
              title="正面舆情"
              value={positive_count}
              prefix={<LikeOutlined />}
              precision={0}
              valueStyle={{ color: '#26890D' }}
            />
          </Card>
        </Col>
      </Row></Card.Grid>
      <Card.Grid style={gridStyle}><Pie {...config} /></Card.Grid>
      <Card.Grid style={gridStyle}><WordCloud {...wordCloudConfig} /></Card.Grid>

    </Card>
  }



  const renderRiskIndicatorsListDetails = (data) => {

    const columns = [
      {
        title: '标题',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: '时间',
        dataIndex: 'time',
        key: 'time',
      },
      {
        title: '来源',
        dataIndex: 'source',
        key: 'source',
        render: (_, {source, url}) => {
          return <a href={url}>{source}</a>
        }
      },
      {
        title: '內容',
        dataIndex: 'content',
        key: 'content',
      },
      {
        title: '輿情',
        dataIndex: 'sentiment',
        key: 'sentiment',
        render: (_, {sentiment}) => {
          switch(sentiment.toLowerCase()) {
            case "positive":
              return <Badge status="success" text="正面" />
            case "neutral":
              return <Badge status="success" text="中性" />
            case "negative":
              return <Badge status="success" text="負面" />
              default:
                return <Badge status="default" text="不明" />
          }
        }
      },
      {
        title: '關鍵詞',
        dataIndex: 'tags',
        key: 'tags',
      },
    ];

    return <Card title="舆情记录" bordered={false} >
      <Table dataSource={data} columns={columns} />
    </Card>
  }

  const { reports = [] } = data;
  return <>
    <Layout style={{ height: "100vh -200px" }}><Content
      style={{
        margin: '24px 16px',
        padding: 24,
        minHeight: 280,
        // background: colorBgContainer,
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>{renderRiskOverview()}</Col>
        <Col span={24}>
          {renderRiskIndicatorsListDetails(reports)}
        </Col>
      </Row>
    </Content></Layout>

  </>
}



const SentimentReportDetail = () => {

  const data = {
    id: 1,
    "entity_id": "9bbb5bbd-79ef-4b84-995f-2cc29d5320d5",
        "entity_name": "正兴隆房地产（深圳）有限公司",
        "created_at": "2022-05-25 09:41",
        "created_by": "Leung, Brent Yun Chuen",
    status: "Success",
    start_date: "2022-01-01 09:41",
    end_date: "2022-12-31 09:41",
    risk_report_id: 1,
    positive_count: 10,
    negative_count: 12,
    neutral_count: 13,
    word_count: [
      {
        key: 1,
        display_name: "投资融资",
        count: 5,
      },
      {
        key: 2,
        display_name: "业务合作",
        count: 3,
      },
    ],
   reports: [
    {
      id: 0,
      ref_id: 'abc',
      title: "21绿景01回售债券未进行转售 将注销509万张债券",
      time: "2022-11-28 16:35",
      source: "新浪网",
      url: "https://k.sina.com.cn/article_1704103183_65928d0f020030y3c.html",
      content: "本文源自:观点网观点网讯：11月28日，正兴隆房地产（深圳）有限公司发布关于“21绿景01”债券转售实施结果的公告。",
      sentiment: "neutral",
      tags: "投资融资"
      },
    {
      id: 1,
      ref_id: 'abc',
      title: "21绿景01回售债券未进行转售 将注销509万张债券",
      time: "2022-11-28 16:35",
      source: "新浪网",
      url: "https://k.sina.com.cn/article_1704103183_65928d0f020030y3c.html",
      content: "本文源自:观点网观点网讯：11月28日，正兴隆房地产（深圳）有限公司发布关于“21绿景01”债券转售实施结果的公告。",
      sentiment: "neutral",
      tags: "投资融资"
      },
    {
      id: 2,
      ref_id: 'abc',
      title: "21绿景01回售债券未进行转售 将注销509万张债券",
      time: "2022-11-28 16:35",
      source: "新浪网",
      url: "https://k.sina.com.cn/article_1704103183_65928d0f020030y3c.html",
      content: "本文源自:观点网观点网讯：11月28日，正兴隆房地产（深圳）有限公司发布关于“21绿景01”债券转售实施结果的公告。",
      sentiment: "neutral",
      tags: "投资融资"
      },
    {
      id: 3,
      ref_id: 'abc',
      title: "21绿景01回售债券未进行转售 将注销509万张债券",
      time: "2022-11-28 16:35",
      source: "新浪网",
      url: "https://k.sina.com.cn/article_1704103183_65928d0f020030y3c.html",
      content: "本文源自:观点网观点网讯：11月28日，正兴隆房地产（深圳）有限公司发布关于“21绿景01”债券转售实施结果的公告。",
      sentiment: "neutral",
      tags: "投资融资"
      },
    ]
}
// console.log(JSON.stringify(data))
  return (
    <>
      <EngagementBasicInfo data={data}/>
      <RiskReportDetails data={data}/>
    </>
  )
}


export default SentimentReportDetail