

export const entitiesSearchResult = [
    {
        "id": "1",
        'title': "深圳市绿景房地产开发有限公司",
        "stock_code": "1234",
        "industry": "Technology",
        "scale": "large",
    },
    {
        "id": "2",
        'title': "深圳市绿景房地产开发有限公司",
        "stock_code": "1234",
        "industry": "Technology",
        "scale": "large",
    },
    {
        "id": "3",
        'title': "深圳市绿景房地产开发有限公司",
        "stock_code": "1234",
        "industry": "Technology",
        "scale": "large",
    },
    {
        "id": "4",
        'title': "深圳市绿景房地产开发有限公司",
        "stock_code": "1234",
        "industry": "Technology",
        "scale": "large",
    },
    {
        "id": "5",
        'title': "深圳市绿景房地产开发有限公司",
        "stock_code": "1234",
        "industry": "Technology",
        "scale": "large",
    },
    {
        "id": "6",
        'title': "深圳市绿景房地产开发有限公司",
        "stock_code": "1234",
        "industry": "Technology",
        "scale": "large",
    },
    {
        "id": "7",
        'title': "深圳市绿景房地产开发有限公司",
        "stock_code": "1234",
        "industry": "Technology",
        "scale": "large",
    },
]

export const myEntities = [
    {
        "id": "1",
        'title': "深圳市绿景房地产开发有限公司",
        "stock_code": "1234",
        "industry": "Technology",
        "scale": "large",
    },
    {
        "id": "2",
        'title': "深圳市绿景房地产开发有限公司",
        "stock_code": "1234",
        "industry": "Technology",
        "scale": "large",
    },
    {
        "id": "3",
        'title': "深圳市绿景房地产开发有限公司",
        "stock_code": "1234",
        "industry": "Technology",
        "scale": "large",
    }
]

export const mockRiskIndicators = [

    { key: 0,
      group: "财务类",
      title: "销售净利率",
      default_weight: "10",
    },
    { key: 1,
      group: "财务类",
      title: "销售净利率",
      default_weight: "10",
    },
    { key: 2,
      group: "财务类",
      title: "销售净利率",
      default_weight: "10",
    },
    { key: 3,
      group: "财务类",
      title: "销售净利率",
      default_weight: "10",
    },
    { key: 4,
      group: "财务类",
      title: "销售净利率",
      default_weight: "10",
    },
    { key: 5,
      group: "财务类",
      title: "销售净利率",
      default_weight: "10",
    },
    { key: 6,
      group: "财务类",
      title: "销售净利率",
      default_weight: "10",
    },
  ]